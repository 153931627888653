import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layouts";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Layout,
    redirect: "/index",
    children: [
        {
            path: "/index",
            name: "index",
            component: () => import("../views/index.vue"),
            meta: {
                title: "国内",
            },
        },
        {
            path: "/versea",
            name: "versea",
            component: () => import("../views/versea.vue"),
            meta: {
                title: "海外",
            },
        },
        {
            path: "/business",
            name: "homepage",
            component: () => import("../views/business.vue"),
            meta: {
                title: "业务流程",
            },
        }
    ],
}]
const router = new VueRouter({
    mode: "history",
    routes
})

export default router