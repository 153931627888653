<template>
    <div class="nav-wrapper" :style="{height:navHeight}">
        <div class="bar-title" :style="{'font-size':fontSize}">跨境电商线上综合公共服务平台</div>
    </div>
</template>

<script>

    export default {
        name: "navBar",
        data() {
            return {
                fontSize: '3rem',
                navHeight:'120px'
            }
        },

        mounted: function () {
            if (this.routerName == 'index') {
                this.fontSize = '2rem'
                this.navHeight = '80px'
            } else {
                this.fontSize = '3.5rem'
                this.navHeight = '120px'
            }
        },
    };


</script>

<style lang="scss" scoped>
    .nav-wrapper {
        height: 120px;
        width: 100%;
        background: url("../../assets/image/nav.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        position: relative;
        color: #b3efff;

        .bar-title {
            font-size: 3rem;
            font-family: "黑体";
            height: 60px;
            line-height: 60px;
            font-weight: bolder;
        }

        .time {
            position: absolute;
            right: 8%;
            top: 50%;
            transform: translateY(-35%);
            font-family: "Time Number";
            font-weight: bold;
            font-size: 1.22rem;
        }

        .mapChoose {
            position: absolute;
            left: 10px;
            bottom: -5px;
            color: #eee;

            .title {
                padding: 4px;
                border-top: 1px solid rgba(147, 235, 248, 0.8);
                border-bottom: 1px solid rgba(147, 235, 248, 0.8);
                cursor: pointer;
                font-size: 14px;
            }

            .icon {
                font-family: "simsun";
                font-size: 25px;
                margin: 0 11px;
            }
        }
    }
</style>
