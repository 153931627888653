<template>
    <div id="app" :style="{width:widthT ,height:heightT}">
        <router-view/>
    </div>
</template>

<script>
    import router from "./router"
    import Vue from 'vue';

    export default {
        data() {
            return {
                widthT: '3840px',
                heightT: '2160px'
            }

        },

        mounted: function () {
            if (this.routerName == 'index') {
                this.widthT ='1920px'
                this.heightT ='1080px'
            }else{
                this.widthT ='3840px'
                this.heightT ='2160px'
            }
        },
    }

    router.beforeEach((to, from, next) => {
        Vue.prototype.routerName = to.name
        next()
    })


</script>

<style lang="scss" scoped>
    #app {
        background-color: #020308;
        overflow: hidden;
    }
</style>
